import { useState } from 'react'
import classnames from 'classnames'
import { Row, Col, Form, ListGroup, Badge, Button, ButtonGroup, InputGroup, FormControl } from 'react-bootstrap'

import ColAuto from "../utils/ColAuto"
import Icon from "../utils/Icon"
import {
  formatNumber, getTotalOdds, getBetWinnings, championshipName,
  teamCrestImage, showCrest, getLocalDate
} from '../../utils'

export default function Ticket({ settings, odds, amount, name, disableName = false, onResetClick, onChangeBetAmount, randomBets, onChangeGamblerName, onRemoveOddClick, onPlaceBet, user, championships }) {
  const totalOdds = getTotalOdds(odds, settings)
  const winnings = getBetWinnings(amount, totalOdds, settings)
  const isDarkTheme = settings.tema_padrao === 1
  const [useBonus, setUseBonus] = useState()

  return (
    <>
      <header className={classnames(['ticket-header pt-2 pb-2', { 'bg-white': !isDarkTheme }])} >
        <Row>
          <Col>
            <h5 className="text-uppercase my-1 ml-2">
            
              {odds.length > 0 ?
                <Badge variant="warning" className='align-baseline mr-2'>{odds.length}</Badge>
                :
                <Icon name="receipt mr-2" />
              }
              Bilhete
            </h5>
          </Col>
          {settings.palpites_surpresa > 0 && <ColAuto className="mr-3">
            <Button block variant="secondary" onClick={() => randomBets()}>
              <Icon name="random" />
            </Button>
          </ColAuto>}
        </Row>
      </header>
      <div className="ticket-scroll" style={{ height: (user && user.nivel === 3 && user.saldo_bonus > 0 ? 48 : 50) + 'vh'}}>
        {odds.length > 0 ?
          <ListGroup variant="flush">
            {odds.map((item) => (
              <ListGroup.Item key={item.match.id_partida}>
                <Row>
                  <Col xs={12} className="text-truncate text-center">
                    <small>{championshipName(item.match)}</small>
                  </Col>
                </Row>
                <Row>
                  <Col xs={8} className="pr-0">
                    <div className="d-flex flex-column">
                      <div className="text-truncate">
                        {showCrest(item.match.id_esporte) &&
                          <img src={teamCrestImage(item.match.escudo_mandante)} className="mr-2 align-middle object-fit" width="20" height="20" border="0" />}
                        {item.match.mandante}
                      </div>
                      <div className="text-truncate">
                        {showCrest(item.match.id_esporte) &&
                          <img src={teamCrestImage(item.match.escudo_visitante)} className="mr-2 align-middle object-fit" width="20" height="20" border="0" />}
                        {item.match.visitante}
                      </div>
                    </div>
                  </Col>
                  <Col xs={4} className="pl-0">
                    <div className="d-flex flex-column">
                      <div role="button" className="text-right" onClick={() => onRemoveOddClick(item)}>
                        <Icon name="trash" />
                      </div>
                      <div className="text-truncate text-right small">
                        <Icon name="clock" /> {getLocalDate(item.match.data, settings.fuso_horario).format('HH:mm')}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="border-top mt-2 pt-2">
                  <Col xs={9} className="pr-0 text-info">
                    {item.live && <Badge variant="danger" className="live-blink mr-1">Live</Badge>}
                    <small><b>{item.odd.categoria}: </b></small>
                    <small>
                      {item.match.id_esporte == 3 || item.match.id_esporte == 6 ?
                        <>{item.odd.id_tipo == 2427 || item.odd.id_tipo == 2621 ? item.match.mandante : item.match.visitante}</>
                        :
                        <>{item.odd.tipo}{item.odd.jogador ? `: ${item.odd.jogador}` : ''}</>
                      }
                    </small>
                  </Col>
                  <Col xs={3} className="pl-0 text-truncate text-right text-info">
                    <small><i className="fas fa-dollar-sign mr-2"></i>{item.odd.cotacao}</small>
                  </Col>
                </Row>
              </ListGroup.Item>
            ))}
          </ListGroup>
          :
          <div className={classnames(['d-flex h-100 flex-column justify-content-center align-items-center', { 'bg-dark': isDarkTheme }])}>
            <div className="text-brown"><Icon name="ticket-alt" /></div>
            <div className="text-brown"><span>Bilhete vazio</span></div>
          </div>
        }
      </div>
      <div className="ticket-content mt-2 pl-2 pr-2">
        <Row>
          <Col xs={12}>
            <div className="d-flex flex-column">
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text>
                    <Icon name="user" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  placeholder="Apostador"
                  value={name}
                  disabled={disableName}
                  autoComplete="off"
                  spellCheck={false}
                  onChange={(e) => onChangeGamblerName(e.target.value)}
                  maxLength="14"
                  pattern="[A-Za-z\s]"
                />
              </InputGroup>
              <Row className="mt-2 mr-0">
                <Col className="pr-0 text-truncate">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text className="rounded-left">
                        <Icon name="usd-square" />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                      className="rounded-0"
                      placeholder="Valor"
                      value={amount}
                      inputMode="numeric"
                      pattern={settings.habilitar_centavos ? "^(\d)?(\,)?(\d{1,2})?$" : "[\d*]"}
                      autoComplete="off"
                      spellCheck={false}
                      onChange={(e) => onChangeBetAmount(e.target.value)}
                    />
                  </InputGroup>
                </Col>
                <Col className="pl-2 b-222 rounded-right text-truncate">
                  <div className="d-flex justify-content-between text-ddd">
                    <div><Icon name="exchange-alt" /></div>
                    <div className="text-right">{formatNumber(winnings)}</div>
                  </div>
                </Col>
              </Row>
              <ButtonGroup className="mt-2">
                <Button variant="secondary" onClick={() => onChangeBetAmount(5)}>5</Button>
                <Button variant="secondary" onClick={() => onChangeBetAmount(10)}>10</Button>
                <Button variant="secondary" onClick={() => onChangeBetAmount(20)}>20</Button>
                <Button variant="secondary" onClick={() => onChangeBetAmount(50)}>50</Button>
                <Button variant="secondary" onClick={() => onChangeBetAmount(100)}>100</Button>
              </ButtonGroup>
              {user && user.nivel === 3 && user.saldo_bonus > 0 && <Form.Check
                checked={useBonus}
                className={classnames('mt-2', { 'text-light': isDarkTheme })}
                type="checkbox"
                label="Usar Saldo de Bônus"
                onChange={(e) => setUseBonus(e.target.checked)}
              />}
              <Row className="mt-2">
                <ColAuto className="pr-2">
                  <Button variant="secondary" block onClick={onResetClick}>
                    <Icon name="undo" />
                  </Button>
                </ColAuto>
                <div className="col pl-0">
                  <Button variant="primary" block onClick={() => onPlaceBet(useBonus)}>
                    <div className="d-flex justify-content-center">
                      <div className="mr-3"><Icon name="check" /></div>
                      <div>Concluir</div>
                    </div>
                  </Button>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}