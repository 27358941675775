import { Button, Container, Row, Col, Badge } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import classnames from 'classnames'
import moment from 'moment-timezone'

import OddButton from '../layout/OddButton'
import ColAuto from '../utils/ColAuto'
import Icon from '../utils/Icon'
import {
  teamCrestImage, showCrest, isSameMatchOdd,
  getLocalDate, getNameInitials, isMainOdd
} from '../../utils'

export default function SportMatchOdds({ match, mainOdds, selectedOdds, onOddClick, onShowOddsClick, live = null, isMobile, timezone, sport, settings }) {
  const isDarkTheme = settings.tema_padrao === 1
  const [disableModal, setDisableModal] = useState(false)
  const [timeLeft, setTimeLeft] = useState(null)
  const [needBlink, setNeedBlink] = useState(false)

  useEffect(() => {
    const needDisable = live && match.cotacoes.filter(odd => odd.cat_id == 1).every(odd => odd.cotacao <= 1.0)
    setDisableModal(needDisable)

    if (live) return

    const i = setInterval(() => {
      const start = getLocalDate(moment().format('YYYY-MM-DD HH:mm:ss'))
      const duration = moment.duration(getLocalDate(match.data, timezone).diff(start))
      const minutes = duration.asMinutes()
      const seconds = duration.asSeconds()

      if (minutes < 60) {
        let time = minutes < 1 ? seconds : minutes

        if (minutes <= 5)
          setNeedBlink(true)

        setTimeLeft(Math.floor(time).toString().padStart(2, '0'))
      }

      if (Math.floor(seconds) <= 0) {
        clearInterval(i)
      }
    }, 1000)

    return () => clearInterval(i)
  }, [match])

  const otherOddsActiveClass = (match) => {
    return selectedOdds.find(
      item => isSameMatchOdd(item, match) && !isMainOdd(item.odd)
    ) ? 'active' : ''
  }

  const renderMainOddButtons = (match) => {
    if (live || !mainOdds.length) {
      return (
        <>
          {[
            { label: 'C', odd: match.homeOdd },
            { label: 'E', odd: (sport == 1 && match.drawOdd) },
            { label: 'F', odd: match.awayOdd }
          ].map(obj =>
            obj.odd && <Col className="pl-2 pr-0" key={obj.odd.id_cotacao}>
              <OddButton
                main
                label={sport === 3 || sport === 6 ? getNameInitials(obj.odd.tipo) : obj.label}
                match={match}
                odd={obj.odd}
                selectedOdds={selectedOdds}
                onClick={() => onOddClick(match, obj.odd, live)}
                isMobile={isMobile}
                settings={settings}
              />
            </Col>)}
          {(sport != 3 && sport != 4 && sport != 6) &&
            <ColAuto className="pl-2 pr-0 mr-2">
              <Button
                block
                variant={isDarkTheme ? 'black' : 'secondary'}
                size={isMobile ? 'sm' : undefined}
                className={classnames([otherOddsActiveClass(match)])}
                onClick={() => onShowOddsClick(match)}
                disabled={disableModal}
              >
                {disableModal
                  ? <Icon name="lock" />
                  : `+ ${match.total_cotacoes || match.cotacoes.length}`}
              </Button>
            </ColAuto>
          }
        </>
      )
    }


    return (
      <>
        {mainOdds.map(odd => <Col key={`${match.id_partida}-${odd.cat_id}-${odd.id_tipo}`} className="pl-2 pr-0">
          <OddButton
            main
            label={sport === 3 || sport === 6 ? getNameInitials(odd.tipo) : (odd.abreviacao ? odd.abreviacao.toString().replace(' ', '') : getNameInitials(odd.tipo))}
            match={match}
            odd={odd}
            selectedOdds={selectedOdds}
            onClick={() => onOddClick(match, odd, live)}
            isMobile={isMobile}
            settings={settings}
          />
        </Col>)}
        {(sport != 3 && sport != 4 && sport != 6) &&
          <ColAuto className="pl-2 pr-0 mr-2">
            <Button
              block
              variant={isDarkTheme ? 'black' : 'secondary'}
              size={isMobile ? 'sm' : undefined}
              className={classnames([otherOddsActiveClass(match)])}
              onClick={() => onShowOddsClick(match)}
              disabled={disableModal}
            >
              {disableModal
                ? <Icon name="lock" />
                : `+ ${match.total_cotacoes || match.cotacoes.length}`}
            </Button>
          </ColAuto>
        }
      </>
    )
  }

  const renderScores = (match) => {
    const mandante = match.placar_mandante === null ? 0 : match.placar_mandante
    const visitante = match.placar_visitante === null ? 0 : match.placar_visitante

    return `${mandante} - ${visitante}`
  }
  const renderTime = (match) => {
    let time = ' · '

    if (match.tempo) {
      time += match.tempo;
    }
    else {
      if (match.tempo_jogo < 45) {
        time += '1T'
      }
      else if (match.tempo_jogo > 45) {
        time += '2T'
      }
      else {
        time += 'INT'
      }
    }
    return time;
  }

  if (!live && timeLeft !== null && +timeLeft <= 0) {
    return null
  }

  if (isMobile) {
    return (
      <>
        <Row className='matchlist py-2 justify-content-between'>
          <Col xs={6} className="pl-0 py-2">
            <div className={classnames(['ml-2 text-truncate', { 'text-light': isDarkTheme }])}>
              {showCrest(sport) &&
                <img src={teamCrestImage(match.escudo_mandante)} className="mr-2 align-middle object-fit" width="20" height="20" border="0" />
              }
              {match.mandante}
            </div>
            <div className={classnames(['ml-2 text-truncate', { 'text-light': isDarkTheme }])}>
              {showCrest(sport) &&
                <img src={teamCrestImage(match.escudo_visitante)} className="mr-2 align-middle object-fit" width="20" height="20" border="0" />
              }
              {match.visitante}
            </div>
          </Col>
          <Col xs={6} className="d-flex pl-5">
            {live && (match.tvstream || match.livestream) &&
              <Col xs={1} className="my-2 pl-0">
                {match.tvstream &&
                  <Icon name="tv-retro" className={classnames([{ 'text-light': isDarkTheme, 'mt-2': !match.livestream }])} />
                }
                {match.livestream &&
                  <Icon name="chart-area" className={classnames([{ 'text-light': isDarkTheme, 'mt-2': !match.tvstream }])} />
                }

              </Col>
            }

            <Col xs={live && (match.tvstream || match.livestream) ? 11 : 12} className="pt-2 pr-0" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
              {live ?
                <>
                  <div className={classnames(['text-truncate', { 'text-light': isDarkTheme }])}>
                    <Badge variant="danger" className="live-blink mr-1">Live</Badge>
                    {renderScores(match)}
                  </div>
                  <div className={classnames([{ 'text-light': isDarkTheme }])}>
                    <Icon style="far" name="clock" /> {match.tempo_jogo.toString().padStart(2, '0')}
                    {renderTime(match)}
                  </div>
                </>
                :
                <>
                  <div className={classnames(['pr-2 text-right text-truncate', { 'text-light': isDarkTheme }])}>
                    <Icon name="calendar-alt" /> {getLocalDate(match.data, timezone).format('DD/MM')}
                  </div>
                  <div className={classnames(['pr-2 text-right text-truncate', { 'text-light': isDarkTheme }])}>
                    <Icon style="far" name="clock" /> {getLocalDate(match.data, timezone).format('HH:mm')}
                  </div>
                </>

              }
            </Col>
          </Col>
          <Col xs={12} className="d-flex px-0">
            {renderMainOddButtons(match)}
          </Col>
        </Row>
      </>
    )
  }

  return (
    <Row className='matchlist'>
      <Col className="pl-0 pt-2 pb-2">
        <div className={classnames(['pl-2 text-truncate', { 'text-light': isDarkTheme }])}>
          {showCrest(sport) &&
            <img src={teamCrestImage(match.escudo_mandante)} className="mr-2 align-middle object-fit" width="20" height="20" border="0" />
          }
          {match.mandante}
        </div>
        <div className={classnames(['pl-2 text-truncate', { 'text-light': isDarkTheme }])}>
          {showCrest(sport) &&
            <img src={teamCrestImage(match.escudo_visitante)} className="mr-2 align-middle object-fit" width="20" height="20" border="0" />
          }
          {match.visitante}
        </div>
      </Col>
      {live && (match.tvstream ?
        <ColAuto className="m-top-7" >
          <Icon name="tv-retro" className={classnames(['my-4', { 'text-light': isDarkTheme }])} />
        </ColAuto>
        :
        match.livestream &&
        <ColAuto className="m-top-7" >
          <Icon name="chart-area" className={classnames(['my-4', { 'text-light': isDarkTheme }])} />
        </ColAuto>
      )
        || live && match.livestream && match.tvstream &&
        <ColAuto className="m-top-7">
          <Icon name="tv-retro" className={classnames(['my-2', { 'text-light': isDarkTheme }])} />
          <br />
          <Icon name="chart-area" className={classnames({ 'text-light': isDarkTheme })} />
        </ColAuto>
      }


      <ColAuto className='pl-0 py-2'>
        <div className={classnames(['pl-2 text-truncate', { 'text-light': isDarkTheme }])}>
          {sport == 1 && <Badge variant="warning" className="ml-1 text-right">
            {match.placar_mandante === null ?
              '0'
              :
              match.placar_mandante
            }
          </Badge>}
        </div>
        <div className={classnames(['pl-2 text-truncate', { 'text-light': isDarkTheme }])}>
          {sport == 1 && <Badge variant="warning" className="ml-1 text-right">
            {match.placar_visitante === null ?
              '0'
              :
              match.placar_visitante
            }
          </Badge>}
        </div>
      </ColAuto>
      <Col className="mr-2 m-top-13">
        <Row>
          <ColAuto className={classnames(['text-truncate top-7 pr-0', { 'text-light': isDarkTheme }])}>
            <Icon style="far" name="clock" margin />
            {live ?
              <>
                {match.tempo_jogo.toString().padStart(2, '0')}
                {match.tempo && ` · ${match.tempo}`}
              </>
              :
              <span className={timeLeft && classnames({ 'diff-blink': needBlink, 'diff': !needBlink })}>
                {timeLeft ?
                  <>
                    00:{timeLeft.toString().padStart(2, '0')}
                  </>
                  :
                  getLocalDate(match.data, timezone).format('HH:mm')
                }
              </span>
            }
          </ColAuto>
          {renderMainOddButtons(match)}
        </Row>
      </Col>
    </Row>
  )
}
