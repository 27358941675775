import DefaultSpinner from '../layout/DefaultSpinner'
import SportsNavbar from '../layout/SportsNavbar'
import SportChampionshipOdds from './SportChampionshipOdds'
import LiveSoccerOdds from './LiveSoccerOdds'
import { getSportId, isSameMatchOdd } from '../../utils'

export default function SportsOdds({ settings, loadedSettings, axios, odds, apiUrl, socketUrl, selectedOdds, setSelectedOdds, selectedChampionship, onChangeChampionships, onSelectChampionship, isMobile, sport, onChangeSport, onItemClick, loadSettings, onError, isDarkTheme }) {
  const handleOddClick = (match, odd, live = false) => {
    const sameMatchOdd = selectedOdds.find((o) => isSameMatchOdd(o, match));
    const _selectedOdds = [...selectedOdds]

    if (sameMatchOdd)
      _selectedOdds.splice(_selectedOdds.indexOf(sameMatchOdd), 1)

    if (!sameMatchOdd || sameMatchOdd.odd.id_tipo !== odd.id_tipo) {
      _selectedOdds.push({
        match,
        odd,
        live,
        api: !live && (!settings.tipos || !settings.tipos[match.cotacao_api_id] || !settings.tipos[match.cotacao_api_id][odd.id_tipo])
      })
    }

    setSelectedOdds(_selectedOdds)
  }

  const renderSport = () => {
    if (!loadedSettings)
      return <DefaultSpinner isDarkTheme={isDarkTheme} />

    if (sport === 'live') {
      return (
        <LiveSoccerOdds
          settings={settings}
          apiUrl={apiUrl}
          socketUrl={socketUrl}
          selectedOdds={selectedOdds}
          selectedChampionship={selectedChampionship}
          onOddClick={handleOddClick}
          onChangeChampionships={onChangeChampionships}
          onSelectChampionship={onSelectChampionship}
          isMobile={isMobile}
          loadSettings={loadSettings}
        />
      )
    }

    const sportId = getSportId(sport);

    return (
      <SportChampionshipOdds
        settings={settings}
        apiUrl={apiUrl}
        selectedOdds={selectedOdds}
        selectedChampionship={selectedChampionship}
        onOddClick={handleOddClick}
        onChangeChampionships={onChangeChampionships}
        onSelectChampionship={onSelectChampionship}
        isMobile={isMobile}
        onError={onError}
        sport={sportId}
        axios={odds}
        isDarkTheme={isDarkTheme}
      />
    )
  }

  if (isMobile) {
    return (
      <div id="page-wrapper" className="home-m-wrapper">
        <SportsNavbar settings={settings} sport={sport} onSelect={onChangeSport} isMobile={isMobile} onItemClick={onItemClick} />
        {renderSport()}
      </div>
    )
  }

  return (
    <>
      <SportsNavbar settings={settings} sport={sport} onSelect={onChangeSport} isMobile={isMobile} onItemClick={onItemClick} />
      <div id="page-wrapper" className="home-wrapper">
        {renderSport()}
      </div>
    </>
  )
}
