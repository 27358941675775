import { Carousel } from 'react-bootstrap'

export default function BannersCarrousel({ banners = [] }) {
  if (banners.length === 0)
    return null

  return (
    <Carousel interval={3000}>
      {banners.length > 0 && banners.map(banner => (
        <Carousel.Item key={banner.id}>
          <img src={banner.caminho} className="img-responsive" alt="Banner" />
        </Carousel.Item>
      ))}
    </Carousel>
  )
}
