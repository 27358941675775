import { useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Col, InputGroup, FormControl, Row, Spinner } from 'react-bootstrap'
import classnames from 'classnames'
import moment from 'moment'

import BannersCarrousel from '../layout/BannersCarrousel'
import DefaultSpinner from '../layout/DefaultSpinner'
import MatchList from './MatchList'
import NoEventMessage from '../layout/NoEventMessage'
import NoInternetMessage from '../layout/NoInternetMessage'
import WeekButtonGroup from '../layout/WeekButtonGroup'
import OddsModal from '../modals/OddsModal'
import Icon from '../utils/Icon'
import {
  getToday,
  normalize,
  useIsMountedRef,
  generateChampionships,
  generateMatches,
  generateOddsData,
  fetcher
} from '../../utils'

const MATCHES_PER_PAGE = 15;

export default function SportChampionshipOdds({
  settings,
  apiUrl,
  axios,
  selectedOdds,
  selectedChampionship,
  onOddClick,
  onChangeChampionships = () => {},
  onSelectChampionship = () => {},
  isMobile,
  onError,
  sport,
  isDarkTheme
}) {
  const today = getToday()

  const [loading, setLoading] = useState(true)
  const [paginatedMatches, setPaginatedMatches] = useState([])
  const [matches, setMatches] = useState([])
  const [error, setError] = useState(false)
  const [query, setQuery] = useState('')
  const [selectedMatch, setSelectedMatch] = useState(null)
  const [selectedDate, setSelectedDate] = useState(today)
  const [championships, setChampionships] = useState([])
  const [filteredChampionships, setFilteredChampionships] = useState([])
  const isMountedRef = useIsMountedRef()
  const [currentPage, setCurrentPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const previousSport = useRef(null)

  useEffect(() => {
    setSelectedDate(today)
    setQuery('')
  }, [sport])

  useEffect(() => {
    setCurrentPage(1)

    if (previousSport.current != sport) {
      loadOdds(false, true, true)
      previousSport.current = sport
    } else {
      loadOdds()
    }
  }, [sport, selectedDate, settings])

  useEffect(() => {
    if (query) {
      const timeout = setTimeout(() => findMatches(paginatedMatches, matches, true), 500)
      return () => clearTimeout(timeout)
    }

    findMatches(paginatedMatches, matches, true)
  }, [query])

  useEffect(() => {
    const end = MATCHES_PER_PAGE * currentPage
    const _paginatedMatches = matches.slice(0, end)
    setPaginatedMatches(_paginatedMatches)
    setHasNextPage(end < matches.length)
    findMatches(_paginatedMatches, matches)
  }, [matches, currentPage])

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (!loading) loadOdds(true)
  //   }, 60000)
  //   return () => clearInterval(interval)
  // }, [query, loading, selectedMatch, sport, selectedDate, settings])

  const findMatches = (_paginated, _matches, filter = false) => {
    if (query) {
      const normalizedQuery = normalize(query)
      _matches = _matches.filter((m) => m.chave.indexOf(normalizedQuery) > -1)
    }
    const _championships = generateChampionships(_matches, settings)
    const _pgChampionships = generateChampionships(_paginated, settings)
    if (!filter) {
      onChangeChampionships(_championships)
      setChampionships(_championships)
    }
    setFilteredChampionships(query ? _championships : _pgChampionships)
  }

  const loadOdds = async (useInterval = false, isMain = true, needNextDay = false) => {
    if (settings && settings.categorias === null) return
    !useInterval && isMain && setLoading(true)

    let mainCategory = +isMain

    if (settings.categorias_destaque && settings.categorias_destaque[sport] && Object.values(settings.categorias_destaque[sport]).length && mainCategory) {
      mainCategory = Object.values(settings.categorias_destaque[sport]).map(categoria => categoria.tipos.map(tipo => tipo.id).join(',')).join(',')
    }

    const body = {
      chave: '',
      tipo: '',
      data: selectedDate,
      esporte: sport,
      principal: mainCategory,
      categorias: Object.values(settings.categorias).filter(c => !c.status_pre_jogo).map(c => c.id_cat).toString() || '',
    }

    if (needNextDay)
      body.proximo_dia = 1

    fetcher(axios, '/api/jogos', body)
      .then((result) => {
        if (!isMountedRef.current) return
        if (result.data == null) {
          setMatches([])
          setChampionships([])
          onChangeChampionships([])
          setLoading(false)
          setError(false)
          return
        }

        if (result.data.proxima_data) {
          const sunday = moment().day(7)
          const limit = moment().add(settings.fechamento_domingo ?
            Math.round(sunday.diff(moment(), 'days'))
            : settings.dias_aposta - 1,
          'day').format('YYYY-MM-DD')

          if (result.data.partidas.length === 0 && result.data.proxima_data > selectedDate && result.data.proxima_data <= limit) {
            setSelectedDate(result.data.proxima_data)
            return
          }

          result.data = result.data.partidas
        }

        const _matches = generateMatches(result.data, settings, false, selectedDate)

        if (selectedMatch !== null) {
          const updatedMatch = _matches.find(match =>
            match.id_partida === selectedMatch.id_partida
          )
          if (updatedMatch)
            showOdds(updatedMatch)
          else
            setSelectedMatch(null)
        }

        setMatches(_matches)
        setLoading(false)
        setError(false)
      })
  }

  const showOdds = (match) => {
    const oddsData = generateOddsData(match)
    setSelectedMatch(Object.assign(match, {oddsData}))
  }

  const renderSearch = () => {
    if (isMobile) {
      return (
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className={classnames(['border-0 rounded-0', { 'bg-dark': isDarkTheme }])}>
              <Icon name="search" />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            className={classnames(['border-0 rounded-0', { 'bg-dark text-light': isDarkTheme }])}
            placeholder="Pesquisar time, liga..."
            value={selectedChampionship ? `${selectedChampionship.key}` : query}
            onChange={(e) => setQuery(e.target.value)}
            onFocus={() => selectedChampionship && onSelectChampionship(null)}
          />
          <InputGroup.Append className="input-group-scroll">
            <div className={classnames(['input-scroll-x', { 'bg-dark': isDarkTheme }])}>
              <WeekButtonGroup settings={settings} selectedDate={selectedDate} onChange={setSelectedDate} isMobile={isMobile} />
            </div>
          </InputGroup.Append>
        </InputGroup>
      )
    }

    return (
      <div className="home-nav p-3">
        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                  <Icon name="search" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme }])}
                placeholder="Pesquisar time, liga..."
                value={selectedChampionship ? `${selectedChampionship.name}` : query}
                onChange={(e) => setQuery(e.target.value)}
                onFocus={() => selectedChampionship && onSelectChampionship(null)}
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                  <Icon name="trophy" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="select"
                className={classnames(['border-0 form-control', { 'bg-dark text-light': isDarkTheme }])}
                custom
                onChange={({target}) => setQuery(target.value)}
              >
                <option value="">Selecione uma liga</option>
                {championships.map((championship) => <option value={championship.name} key={championship.key}>{championship.name}</option>)}
              </FormControl>
            </InputGroup>
          </Col>
          <Col>
            <WeekButtonGroup settings={settings} selectedDate={selectedDate} onChange={setSelectedDate} isMobile={isMobile} />
          </Col>
        </Row>
      </div>
    )
  }

  if (loading)
    return <DefaultSpinner isDarkTheme={isDarkTheme} />

  if (error)
    return <NoInternetMessage />

  if (!query && !championships.length)
    return <NoEventMessage showWeek settings={settings} selectedDate={selectedDate} onChange={setSelectedDate} />

  return (
    <>
      <BannersCarrousel apiUrl={apiUrl} banners={settings.banners} />
      {renderSearch()}
      {selectedChampionship
        ? <MatchList
            championship={selectedChampionship}
            isMobile={isMobile}
            isDarkTheme={isDarkTheme}
            settings={settings}
            timezone={settings.fuso_horario}
            sport={sport}
            selectedOdds={selectedOdds}
            onOddClick={onOddClick}
            onShowOddsClick={showOdds}
          />
        : <InfiniteScroll
            dataLength={paginatedMatches.length}
            next={() => setCurrentPage(old => old + 1)}
            hasMore={hasNextPage}
            loader={<Col className="text-center"><Spinner/></Col>}
            scrollableTarget={isMobile ? null : 'page-wrapper'}
            style={{ overflowX: 'hidden' }}
          >
          {filteredChampionships.map(championship => <MatchList
            key={championship.key}
            championship={championship}
            isMobile={isMobile}
            isDarkTheme={isDarkTheme}
            settings={settings}
            timezone={settings.fuso_horario}
            sport={sport}
            selectedOdds={selectedOdds}
            onOddClick={onOddClick}
            onShowOddsClick={showOdds}
          />)}
        </InfiniteScroll>
      }
      <OddsModal
        match={selectedMatch}
        selectedOdds={selectedOdds}
        onOddClick={(m,o) => {setSelectedMatch(null); onOddClick(m,o)}}
        onHide={() => setSelectedMatch(null)}
        isMobile={isMobile}
        timezone={settings.fuso_horario}
        axios={axios}
        settings={settings}
        sport={sport}
      />
    </>
  )
}
