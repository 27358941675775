import classnames from 'classnames'
import { Button, ButtonGroup, Dropdown } from 'react-bootstrap'
import moment from 'moment'

export const DATE_FORMAT = 'YYYY-MM-DD'

export default function WeekButtonGroup({ settings, selectedDate = null, onChange = () => { }, isMobile, fullWidth = false }) {
  const handleClick = (key) => {
    onChange(key)
  }
  const isDarkTheme = settings.tema_padrao === 1
  const isActive = (key) => selectedDate == key

  const renderContent = () => {
    const sunday = (moment().day() == 0 ? moment() : moment().day(7)).endOf('day')

    if (isMobile) {
      return (
        <ButtonGroup className={classnames(['week-btns', { 'w-100': fullWidth }])}>
          {Array.from({ length: settings.dias_aposta }, (_, i) => {
            const dayOfWeek = moment().add(i, 'd')

            if (settings.fechamento_domingo && dayOfWeek.isAfter(sunday, 'day'))
              return null

            const key = dayOfWeek.format(DATE_FORMAT)
            return (
              <Button key={key} variant={isDarkTheme ? 'dark' : (isActive(key) ? 'primary' : 'light')} className={classnames(['border-0 text-uppercase', { 'active': isActive(key), 'rounded-0': !fullWidth, 'text-light': isDarkTheme }])} onClick={() => handleClick(key)}>
                {dayOfWeek.format('ddd')}
              </Button>
            )
          })}
        </ButtonGroup>
      )
    }

    const dias_aposta = settings.dias_aposta > 4 ? settings.dias_aposta - (3 - (7 - settings.dias_aposta)) : settings.dias_aposta
    return (
      <ButtonGroup className="w-100">
        {Array.from({ length: dias_aposta }, (_, i) => {
          const dayOfWeek = moment().add(i, 'd')

          if (settings.fechamento_domingo && dayOfWeek.isAfter(sunday, 'day'))
            return null

          const key = dayOfWeek.format(DATE_FORMAT)
          if (i < 3) {
            return (
              <Button key={key} variant={isDarkTheme ? 'dark' : (isActive(key) ? 'primary' : 'light')} className={classnames(['border-0 text-uppercase', { 'active': isActive(key), 'text-light': isDarkTheme }])} onClick={() => handleClick(key)}>
                {dayOfWeek.format('ddd')}
              </Button>
            )
          }

          return (
            <Dropdown key="other" as={ButtonGroup}>
              <Dropdown.Toggle variant={isDarkTheme ? 'dark' : (isActive(key) ? 'primary' : 'light')} className={classnames(['border-0 text-uppercase', { 'text-light': isDarkTheme }])}>Mais</Dropdown.Toggle>
              <Dropdown.Menu className="mr-2">
                {Array.from({ length: settings.dias_aposta - (dias_aposta - 1) }, (_, i) => {
                  const day = moment().add(dias_aposta + i - 1, 'd')
                  const key = day.format(DATE_FORMAT)
                  let dayname = day.format('dddd').replace('-feira', '')
                  dayname = dayname.charAt(0).toUpperCase() + dayname.slice(1)

                  return <Dropdown.Item key={key} active={isActive(key)} onClick={() => handleClick(key)}>{dayname}</Dropdown.Item>
                })}
              </Dropdown.Menu>
            </Dropdown>
          )
        })}
      </ButtonGroup>
    )
  }

  return renderContent()
}
