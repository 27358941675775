import io from 'socket.io-client'
import { useEffect, useRef, useState } from 'react'
import { Col, InputGroup, FormControl, Row } from 'react-bootstrap'
import classnames from 'classnames'
import moment from 'moment'

import MatchList from './MatchList'
import BannersCarrousel from '../layout/BannersCarrousel'
import NoInternetMessage from '../layout/NoInternetMessage'
import NoEventMessage from '../layout/NoEventMessage'
import DefaultSpinner from '../layout/DefaultSpinner'
import OddsModal from '../modals/OddsModal'
import { normalize, createChampionshipHash } from '../../utils'
import Icon from '../utils/Icon'
import {
  generateChampionships,
  generateMatch,
  generateMatches,
  generateOddsData
} from '../../utils/odds'

export default function LiveSoccerOdds({
  settings,
  apiUrl,
  socketUrl,
  selectedOdds,
  selectedChampionship,
  onOddClick,
  onChangeChampionships = () => {},
  onSelectChampionship = () => {},
  isMobile
}) {
  const isDarkTheme = settings.tema_padrao === 1

  const [query, setQuery] = useState('')
  const eventQuery = useRef('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [selectedMatch, setSelectedMatch] = useState(null)
  const [matchesById, setMatchesById] = useState({})
  const [matches, setMatches] = useState([])
  const [socket, setSocket] = useState(null)
  const [championships, setChampionships] = useState([])
  const [filteredChampionships, setFilteredChampionships] = useState([])

  const findMatches = (_matches, filter = false) => {
    const normalizedQuery = normalize(query)
    _matches = _matches.filter((m) => m.chave.indexOf(normalizedQuery) > -1)

    const _championships = generateChampionships(_matches, settings).map(c => {
      const campeonato = settings.campeonatos[createChampionshipHash(c, ["country", "name"])]

      if (campeonato) {
        c.favorite = campeonato.favorito
        c.live = campeonato.ao_vivo
      }

      c.matches.sort((a, b) => {
        const diff = moment(a.data).diff(moment(b.data))

        if (diff > 0)
          return 1
        if (diff < 0)
          return -1
        return a.chave.localeCompare(b.chave)
      })

      return c
    }).filter(c => {
      return c.live !== 0
    }).sort((a, b) => {
      return b.favorite - a.favorite
    })

    if (eventQuery.current !== query) {
      setQuery(eventQuery.current)
    } else {
      if (!filter) setChampionships(_championships)
      setFilteredChampionships(_championships)
      onChangeChampionships(_championships)
    }
  }

  useEffect(() => {
    if (matches.length) {
      const timeout = setTimeout(() => findMatches(matches, true), 500)
      return () => clearTimeout(timeout)
    }
  }, [query])

  useEffect(() => {
    const socket = io(socketUrl, {path: '/api/live/socket.io'})

    socket.on('live_data', (data) => {
      const _matches = generateMatches(data, settings, true)
      const _matchesById = _matches.reduce((obj, x) => {
        obj[x.id_partida] = x
        return obj
      }, {})
      setMatchesById(_matchesById)
      setMatches(_matches)
      findMatches(_matches)
      setLoading(false)
      setError(false)
    })

    socket.on('connect_error', (reason) => {
      setLoading(false)
      setError(true)
      console.log('connect_error', reason)
    })

    socket.on('disconnect', (reason) => {
      setError(true)
      console.log('disconnect', reason)
    })

    setSocket(socket)
    return () => socket.close()
  }, [])

  useEffect(() => {
    if (socket === null) return

    const updateMatch = (data) => {
      if (data.tempo_jogo >= settings.tempo_bloqueio_ao_vivo)
        return

      const match = generateMatch(data, settings, true)
      const _matchesById = Object.assign({}, matchesById, {
        [match.id_partida]: match
      })
      const _matches = [...Object.values(_matchesById)]
      setMatchesById(_matchesById)
      setMatches(_matches)
      findMatches(_matches)
      setError(false)

      if (selectedMatch && selectedMatch.id_partida === match.id_partida)
        showOdds(match)
    }

    const removeMatch = (data) => {
      const _matchesById = Object.assign({}, matchesById)
      delete _matchesById[data.id_partida]
      const _matches = [...Object.values(_matchesById)]
      setMatchesById(_matchesById)
      setMatches(_matches)
      findMatches(_matches)
      setError(false)
    }

    socket.on('live_match', updateMatch)
    socket.on('remove_match', removeMatch)

    return () => {
      socket.off('live_match', updateMatch)
      socket.off('remove_match', removeMatch)
    }
  }, [socket, matches, matchesById, selectedMatch])

  const showOdds = (match) => {
    const oddsData = generateOddsData(match, true, Object.values(settings.categorias))
    setSelectedMatch(Object.assign(match, {oddsData}))
  }

  const handleQuery = (value) => {
    eventQuery.current = value
    setQuery(value)
  }

  const renderSearch = () => {
    if (isMobile) {
      return (
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className={classnames(['border-0 rounded-0', { 'bg-dark': isDarkTheme }])}>
              <Icon name="search" />
            </InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            className={classnames(['border-0 rounded-0', { 'bg-dark text-light': isDarkTheme }])}
            placeholder="Pesquisar time, liga..."
            value={selectedChampionship ? `${selectedChampionship.key}` : query}
            onChange={({ target }) => handleQuery(target.value)}
            onFocus={() => selectedChampionship && onSelectChampionship(null)}
          />
        </InputGroup>
      )
    }
    return (
      <div className="home-nav p-3">
        <Row>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                  <Icon name="search" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                className={classnames(['border-0', { 'bg-dark text-light': isDarkTheme }])}
                placeholder="Pesquisar time, liga..."
                value={selectedChampionship ? `${selectedChampionship.name}` : query}
                onChange={({ target }) => handleQuery(target.value)}
                onFocus={() => selectedChampionship && onSelectChampionship(null)}
              />
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text className={classnames(['border-0', { 'bg-dark': isDarkTheme }])}>
                  <Icon name="trophy" />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <FormControl
                as="select"
                className={classnames(['border-0 form-control', { 'bg-dark text-light': isDarkTheme }])}
                custom
                onChange={({target}) => setQuery(target.value)}
              >
                <option value="">Selecione uma liga</option>
                {championships.map((championship) => <option value={championship.name} key={championship.key}>{championship.name}</option>)}
              </FormControl>
            </InputGroup>
          </Col>
        </Row>
      </div>
    )
  }

  if (loading)
    return <DefaultSpinner isDarkTheme={isDarkTheme} />

  if (error)
    return <NoInternetMessage isDarkTheme={isDarkTheme} />

  if (!query && !championships.length)
    return <NoEventMessage settings={settings} />

  return (
    <>
      <BannersCarrousel apiUrl={apiUrl} banners={settings.banners} />
      {renderSearch()}
      {filteredChampionships.map((championship) => {
        if (selectedChampionship && championship !== selectedChampionship)
          return null

        return <MatchList
          championship={championship}
          isMobile={isMobile}
          isLive={true}
          isDarkTheme={isDarkTheme}
          settings={settings}
          timezone={settings.fuso_horario}
          sport={1}
          selectedOdds={selectedOdds}
          onOddClick={onOddClick}
          onShowOddsClick={showOdds}
        />
      })}

      <OddsModal
        settings={settings}
        match={selectedMatch}
        selectedOdds={selectedOdds}
        onOddClick={(m,o) => {setSelectedMatch(null); onOddClick(m,o, true)}}
        onHide={() => setSelectedMatch(null)}
        isMobile={isMobile}
        timezone={settings.fuso_horario}
        isLive={true}
        sport={1}
      />
    </>
  );
}
